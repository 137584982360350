@import "../../theme/colors";

.logo-component {
    font-family: 'Google Sans', 'sans-serif';
    font-weight: 700;
    display: inline-flex;
    color: white;
    .image {
        width: 50px;

        &.small {
            padding: 8px;
        }
    }
    .name {
        padding-top: 10px;
        padding-left: 10px;
        font-size: 20px;

        &.pink {
            color: $logo-color-1;
        }

        &.small {
            padding-left: 0;
        }
    }
}
