@import "colors";

.standard-toolbar {
    --background: white;

    @media (min-width: 900px) {
        .logo-component {
            margin-left: 10vw;
        }
        .right-column {
            margin-right: 10vw;
        }
    }
}

.standard-content {
    --background: #{$page-background-color};
}

.standard-page-body {
    padding: 15px;
    max-width: 1000px;
    margin: 0 auto;
}

.standard-tabbar {
    --background: white;
    height: 70px;
    border: none;
    @media (min-width: 600px) {
        padding-left: 150px;
        height: 50px;
    }
    @media (min-width: 1200px) {
        padding-left: 0;
    }

    .logo-tab-button {
        position: absolute;
        display: none;
        @media (min-width: 600px) {
            display: inline-flex;
            left: 20px;
        }
        @media (min-width: 1000px) {
            display: inline-flex;
            left: 100px;
        }
    }

    &.web {
        .icon {
            margin-right: 4px;
        }
    }


}

.tabbar-container {
    display: flex;
    flex-direction: row;
}

a {
    text-decoration: none;
}
