$break-point: 450px;

.web-navigation-bar-component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 15px 25px;

    .bar-row {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        max-width: 1200px;
        margin: 0 auto;
    }

    .menu-button {
        display: block;
        @media(min-width: $break-point) {
            display: none;
        }
        --color: white;
    }

    .logo {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        color: white;
        font-family: 'Google Sans', 'sans-serif';
        font-size: 28px;
        font-weight: bold;
        .image {
            width: 50px;
        }
        .name {
            margin-top: 4px;
            margin-left: 10px;
        }
    }

    .login-link {
        margin-right: 20px;
        text-decoration: none;
        color: #FFF;
        display: none;
        margin-top: 10px;
        @media(min-width: $break-point) {
            display: block;
        }
    }

    .right-column {
        display: flex;
        @media(min-width: $break-point) {
            margin-top: 3px;
        }
    }

    .browse-button {
        display: none;
        @media(min-width: $break-point) {
            display: flex;
        }
    }

}
