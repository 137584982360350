$page-background-color: #FFF;

$logo-gradient: linear-gradient(45deg, rgba(238, 76, 151, 1)  0%, rgba(178, 92, 146, 1) 100%);
$logo-gradient-horizontal: linear-gradient(180deg, rgba(238, 76, 151, 1)  0%, rgba(178, 92, 146, 1) 100%);


$icebreaker-background-color: linear-gradient(145deg, rgba(249,81,145,1) 0%, rgba(252,95,97,1) 100%);
$icebreaker-contrast-color: #D83A33;
$icebreaker-background-color-alt: linear-gradient(145deg, rgba(191,86,185,1) 0%, rgba(76,74,150,1) 100%);
$icebreaker-background-color-2: linear-gradient(145deg, rgba(98,57,131,1) 0%, rgba(209,37,125,1) 100%);
$poll-background-color: linear-gradient(145deg, rgba(249,81,145,1) 0%, rgba(252,95,97,1) 100%);
$icebreaker-color-3: #4C4A96FF;
$logo-color-1: #D2599D;
$logo-color-2: #bf5695;
$logo-color-3: #EE4C97;
$logo-color-4: #B25C92;
$mojito-blue-1: #4C4A96FF;
$mojito-link-blue: #4C57C3;
$mojito-link-pink: #EE4C97;
$mojito-warning: #ff8d4b;
$mojito-faded-text: #888;

$section-container-border-radius: 10px;

$faded-text: #888;


@mixin sectionContainer() {
    border: 1px solid #E0DFDC;
    background: #FFF;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 0;
    @media (min-width: 510px) {
        padding: 20px;
        border-radius: $section-container-border-radius;
    }
}
