.logged-out-home-page-web {
    font-family: 'Google Sans', 'sans-serif';

    .service-section {
        text-align: center;
        padding: 30px 20px 40px 20px;

        @media (min-width: 600px) {
            padding: 50px 20px 90px 20px;
        }

        @media (min-width: 1200px) {
            padding: 60px 20px 60px 20px;
        }

        >.title {
            margin-bottom: 30px;
            font-size: 24px;
            font-weight: 700;
            @media (min-width: 990px) {
                font-size: 34px;
            }
        }

        p {
            font-family: 'Cormorant Garamond', "Times New Roman";
            max-width: 650px;
            margin: 0 auto;
            font-size: 22px;
        }

        >.row {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            justify-content: space-around;
            @media (min-width: 750px) {
                flex-direction: row;
                max-width: 900px;
            }
        }

    }

    .sign-up-row {
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }

    .feature-box-section {

        &.icn-pattern{
            position: relative;
            z-index: 1;
            &::before{
                content: '';
                width: 150px;
                opacity: 1;
                height: 400px;
                background-image: url(../../assets/HomePage/icn-pattern-left.svg);
                background-position: left 0;
                background-repeat: no-repeat;
                position: absolute;
                left: 0;
                top: 15%;
                z-index: -1;
            }
            &.icn-pattern-right{
                &::before{
                    background-position: right 0;
                    right: 0;
                    left: inherit;
                    top: 20px;
                }
            }
        }

        .imagebox {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0;
            flex-wrap: wrap;
            width: 100%;
            height: 500px;


            &.left {
                @media (min-width: 768px){
                    border-radius: 0 50px 50px 0;
                }
            }

            &.right {
                @media (min-width: 768px){
                    border-radius: 50px 0  0 50px;
                }
            }

            &.people-dancing {
                background-image: url("https://mojito-dating.imgix.net/assets/website/people-dancing.jpg?w=1000");
                background-size: cover;
            }

            &.couple-hugging {
                background-image: url("https://mojito-dating.imgix.net/assets/website/couple-hugging.jpg?w=1000");
                background-size: cover;
            }
        }

        .container {
            max-width: 100%;
            @media (min-width: 576px){
                max-width: 100%
            }
            @media (min-width: 768px){
                max-width: 720px
            }
            @media (min-width: 1200px){
                max-width: 990px;
            }
        }

        .container-v2 {
            width: 100%;
            margin-right: auto;
            margin-left: auto;

            @media (min-width: 768px){
                width: 88vw;
            }
        }

        .mojito-feature-box {
            display: flex;
            align-items: center;
            border-radius: 20px;
            flex-wrap: wrap;
            flex-direction: column;
            @media (min-width: 768px){
                flex-direction: row;
                border-radius: 50px;
            }
            .content{
                display: inline-flex;
                width: 100%;
                flex-wrap: wrap;
                flex: 1;
                text-align: center;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                @media (min-width: 768px){
                    text-align: left;
                    justify-content: left;
                    align-items: flex-start;
                }
                h2 {
                    line-height: normal;
                    color: #B65B92;
                    font-weight: 700;
                    width: 100%;
                    font-size: 20px;
                    margin: 0;
                    @media (min-width: 768px){
                        font-size: 24px;
                    }
                }
                p {
                    font-size: 22px;
                    line-height: 24px;
                    color: #242222;
                    width: 100%;
                    margin-bottom: 20px;
                    max-width: 400px;
                    font-family: 'Cormorant Garamond', "Times New Roman";
                    font-weight: 500;
                    @media (min-width: 768px){

                        line-height: 26px;
                        margin-bottom: 30px;
                        max-width: 100%;
                    }

                }
                .mojito-button {
                    font-weight: 500;
                }
            }
        }

        &.mojito-lounge-left {
            margin-bottom: 50px;
            @media (min-width: 768px){
                margin-bottom: 100px;
            }

            .row {
                display: flex;
                flex-direction: column;
                @media (min-width: 768px) {
                    flex-direction: row;
                }
                .col {
                    width: 100%;
                    @media (min-width: 768px) {
                        width: 50%;
                    }
                }
            }

            .mojito-feature-box {
                height: auto;
                @media (min-width: 768px){
                    height: 100%
                }
                .content{
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 30px;
                    max-width: 100%;
                    @media (min-width: 768px){
                        max-width: 500px;
                        padding-top: 0;
                        padding-left: 30px;
                    }
                    @media (min-width: 1201px){
                        padding-left: 50px;
                    }
                }
            }
        }

        &.mojito-lounge-right {
            margin-bottom: 50px;
            @media (min-width: 768px){
                margin-bottom: 100px;
            }

            .row {
                display: flex;
                flex-direction: column-reverse;
                @media (min-width: 768px) {
                    flex-direction: row;
                }
                .col {
                    width: 100%;
                    @media (min-width: 768px) {
                        width: 50%;
                    }
                }
            }


            .mojito-feature-box {
                height: auto;
                @media (min-width: 768px){
                    height: 100%;
                    justify-content: right;
                }
                .content{
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-top: 30px;
                    max-width: 100%;
                    @media (min-width: 768px){
                        text-align: right;
                        padding-right: 30px;
                        max-width: 500px;
                        padding-top: 0;
                        align-items: flex-end;
                    }

                    @media (min-width: 1201px){
                        padding-right: 50px;
                    }
                }
            }
        }
    }

    .mojito-invitation-section {
        text-align: center;
        color: #FFF;
        padding: 85px 0;
        background: transparent url(../../assets/HomePage/invitation-banner.png) no-repeat scroll center center;
        background-size: cover;
    }

    .mojito-invitation-wrapper {
        background-color: rgba(255,255,255,0.06);
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
        padding: 20px;
        max-width: 980px;
        display: inline-block;
        width: 100%;
        @media (min-width: 500px) {

            padding: 92px 30px 100px;
        }
    }

    .mojito-container {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-height: 460px;

        @media(min-width: 1800px) {
            max-height: 780px;
        }

        @media (min-width: 1200px) {
            max-width: 1140px;
            max-height: 600px;
        }

        @media (min-width: 992px) {
            max-width: 960px;
        }

        @media (max-width: 991px) {
            max-width: 100%;
        }
    }
}
