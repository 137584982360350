.register-login-page-component{
  background-color: #3E3E3E;
  background-image: linear-gradient(90.31deg, #8E57CE 0.24%, #4C57C3 99.72%);
  height: 100%;
  width: 100%;
  min-height: 100vh;

  .header{
    padding-top: 24px;
    justify-content: center;
    display: flex;
  }

  .header-register-button-container{
    float: right;
    padding-top: 10px;
    @media (min-width: 768px){
      padding-top: 10px;
    }
    .header-register-button{
      color: #fff;
      font-size:12px;
      @media (min-width: 560px){
        font-size: 14px;
      }
    }
  }

  .header-login-button-container{
    display: block;

    .header-login-button{
      color: #fff;
      font-size:12px;
    }
  }


  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto 0 auto;
    width: 90%;
    max-width: 600px;
    @media (min-width: 560px){
      font-size: 14px;
    }

    &.forgot-password {
      margin-top: 15vh;
    }

    &.reset-password {
      margin-top: 12vh;
    }
  }

  .form-card-wrapper {
    width: 100%;
  }

  .login-form-card {
    border-radius: 10px;
    box-shadow: none;
    display: block;
    padding: 30px 150px;
    width: 650px;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }

  .register-login-form-card {
    border-radius: 10px;
    box-shadow: none;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    padding: 16px;

    @media (min-width: 768px){
      width: 500px;
      padding: 30px 30px;
    }
    .form-card-header{
      margin-bottom: 8px;
      @media (min-width: 768px){
        padding-left: 0;
        padding-top: 0;
      }
    }
    .sub-text{
      color: #fff;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .social-media-buttons {
      display: inline-block;
      padding-top: 24px;
      padding-left: 5%;
      padding-bottom: 8px;
      @media (min-width: 768px){
        padding-left: 0px;
      }

      .facebook-button {
        background-color: #fff;
        width: 125px;
        height: 30px;
        font-size: 10px;
        font-weight: 500;
        justify-content: flex-start;
        margin-right: 4px;

        @media (min-width: 375px) {
          width: 145px;
          margin-right: 6px;
        }
        @media (min-width: 425px){
          margin-right: 8px;
          width: 165px;
        }

        .icon-button {
          color: #4267B2;
          margin: 0px 16px 0px 8px;
        }
      }

      .google-button {
        background-color: #fff;
        width: 125px;
        height: 30px;
        font-size: 10px;
        font-weight: 500;
        margin-left: 4px;

        @media (min-width: 375px) {
          width: 145px;
          margin-left: 6px;
        }
        @media (min-width: 425px){
          margin-left: 8px;
          width: 165px;
        }
      }
    }

    .form-divisor-container {
      display: flex;
      justify-content: center;

      .form-divisor {
        width: 115px;
        margin-top: 14px;
        border-top: 2px solid #fff;

        @media (min-width: 375px) {
          width: 135px;
        }
        @media (min-width: 425px){
          width: 160px;
        }
      }

      .form-divisor:first-child {
        margin-right: 8px;
      }

      .form-divisor:last-child {
        margin-left: 8px;
      }
    }

    .text-field {
      width: 100%;
      margin-bottom: 20px;
      margin-top: 20px;
      border-radius: 4px;
      background-color: #fff;
      color: #000;
      font-size: 20px;
      --padding-bottom: 15px;
      --padding-top: 15px;
      --padding-start: 15px;
      --padding-end: 15px;
    }

    .text-field:last-child {
      margin-bottom: 16px;
    }


    .submit-button-container {
      text-align: center;
    }

    .submit-button {
      background-color: #000000;
      color: #dbdbdb;
      border-radius: 40px;
      width: 150px;
      margin: 0;
      @media (min-width: 768px) {
        width: 200px;
        margin-top: 20px;
      }
    }
  }

  .link-container {
    margin: 20px auto;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    @media (min-width: 768px){
      width: 500px;
    }
  }

  .register-caption {
    display: block;
    margin-bottom: 16px;
    @media (min-width: 768px){
      margin-top: 16px;
    }
  }

  .error-text {
    background-color: red;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
}
