.home-page-banner-component {
    font-family: 'Google Sans', sans-serif;
    overflow: hidden;

    .wrapper {
        height: 500px;
        position: relative;
        width: 100%;
        background-position-x: 70%;
        background-size: cover;
        background-image: url("https://mojito-dating.imgix.net/assets/website/landing-page-hero-v2.jpg?h=1000");

        @media (min-width: 500px) {
            background-position-x: 35%;
        }

        @media (min-width: 600px) {
            background-position-x: 42%;
        }

        @media (min-width: 700px) {
            height: 600px;
            background-position-x: 20%;
        }

        @media (min-width: 800px) {
            height: 700px;
            background-position-x: 20%;
        }
    }


    .banner-content {
        padding-top: 20px;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;

        @media (min-width: 600px) {
            padding-left: 30px;
        }

        @media (min-width: 700px) {
            padding-top: 10vh;
            padding-left: 10vw;
        }

        @media (min-width: 900px) {
            padding-top: 5vh;
            padding-left: 10vw;
        }

        @media (min-width: 1200px) {
            padding-top: 10vh;
            padding-left: 10vw;
        }


        .title-small {
            color: white;
            font-size: 20px;
            margin: 0;
            line-height: 1.2;
            font-weight: 500;
            text-align: right;
            text-shadow: #000 2px 2px 10px;

            @media (min-width: 500px) {
                text-shadow: unset;
                text-align: left;
                color: black;
            }

            @media (min-width: 800px) {
                font-size: 45px;
            }
        }

        .title-big {
            color: white;
            font-size: 50px;
            font-weight: 700;
            margin: 0;
            line-height: 1.2;
            text-align: right;
            text-shadow: #000 2px 2px 10px;

            @media (min-width: 500px) {
                text-shadow: unset;
                text-align: left;
                color: black;
            }

            @media (min-width: 800px) {
                font-size: 70px;
                height: 82px;
            }

            @media (min-width: 1200px) {
                font-size: 78px;
                height: 95px;
            }
        }

        p {
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            color: white;
            text-shadow: #000 2px 2px 10px;
            max-width: 150px;
            margin: 20px 0 0 auto;

            @media (min-width: 500px) {
                text-shadow: unset;
                max-width: unset;
                text-align: left;
                color: black;
                margin: 20px 0 0 0;
            }

            @media (min-width: 800px) {
                margin-top: 25px;
                font-size: 18px;
                line-height: 22px;
            }
        }

        .banner-button {
            display: inline-block;
            margin-top: 25px;
        }

        .logo-png {
            width: 100px;
            margin-bottom: 190px;


            @media (min-width: 500px) {
                width: 150px;
                margin-bottom: 120px;
            }

        }

    }

}
