$break-point: 500px;

.logged-out-web-footer {
    padding: 24px 0;
    background-color: #242222;
    color: #FFF;
    font-family: 'Google Sans', 'sans-serif';

    .left-column {
        margin-bottom: 20px;
        @media (min-width: $break-point) {
            margin-bottom: unset;
        }
    }


    .row {
        padding: 0 20px;
        max-width: 900px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        @media (min-width: $break-point) {
            flex-direction: row;
        }
    }


    a {
        margin-right: 20px;
        color: #FFF;
        text-decoration: none;
    }

    .copyright {
        text-align: center;
        margin-top: 10px;
        font-size: 12px;
        color: #E0DFDC;
    }
}
