
@font-face {
    font-family: 'Google Sans';
    src: url('../assets/fonts/GoogleSans/GoogleSans-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../assets/fonts/GoogleSans/GoogleSans-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Google Sans';
    src: url('../assets/fonts/GoogleSans/GoogleSans-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('../assets/fonts/CormorantGaramond/CormorantGaramond-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('../assets/fonts/CormorantGaramond/CormorantGaramond-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('../assets/fonts/CormorantGaramond/CormorantGaramond-Bold.ttf') format('truetype');
    font-weight: 700;
}
