.logged-out-mobile-menu-component {
    .wrapper {
        padding: 30px;
    }
    .link {
        display: block;
        margin-bottom: 30px;
        text-decoration: none;
        color: #555;
    }

    .logo {
        height: 40px;
        margin-bottom: 30px;
    }
}
