@import "../../theme/colors";

.mojito-button-component {
    height: 35px;
    line-height: 35px;
    color: #fff;
    font-size: 14px;
    border: none;
    text-decoration: none;
    display: inline-flex;

    &.button {
        background: -webkit-linear-gradient(180deg, #3E3E3E 0%, #000000 50%, #3E3E3E 100%);
        background: -moz-linear-gradient(180deg, #3E3E3E 0%, #000000 50%, #3E3E3E 100%);
        background: linear-gradient(180deg, #3E3E3E 0%, #000000 50%, #3E3E3E 100%);
        background-size: 100% 200%;
        padding: 0 25px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border-radius: 50px;
    }

    &.link {
        .icon {
            margin-right: 10px;
        }
    }

    &:hover {
        color: #fff;
        background-position: 0 100%;
    }

    &.white {
        background: #fff none no-repeat scroll 0 0;
        color: #242222;
    }

    &.white:hover {
        background: #fff none no-repeat scroll 0 0;
        color: #4C57C3;
    }

    .label.pink {
        color: $logo-color-1;
    }

    .icon {
        margin-left: 5px;
        padding-top: 2px;

        &.pink {
            color: $logo-color-1;
        }
    }

}
